import { chakra, Link, useMultiStyleConfig } from "@chakra-ui/react";
import { createContext } from "src/lib/react";
import type { Section } from "src/sections/renderSections";

type SectionNavigatorItemType = { anchorId: string; title?: string };

export const [SectionNavigatorContext, useSectionNavigatorContext] =
	createContext<{
		currentSectionId?: string;
		allSections: Array<{ section: Section } & SectionNavigatorItemType>;
	}>("SectionNavigatorContext");

const SectionNavigatorItem: React.FC<SectionNavigatorItemType> = ({
	anchorId,
	title,
}) => {
	const styles = useMultiStyleConfig("SectionNavigator");
	const { currentSectionId } = useSectionNavigatorContext();
	const isCurrent = currentSectionId === anchorId;

	if (!title) {
		return null;
	}

	return (
		<Link
			title={title}
			sx={styles.item}
			href={`#${anchorId}`}
			className={isCurrent ? "is-selected" : ""}
			id={`click_section_navigation_${anchorId}`}
			aria-label={title}
		/>
	);
};

export const SectionNavigator: React.FC<{
	sections: Array<SectionNavigatorItemType>;
}> = ({ sections }) => {
	const styles = useMultiStyleConfig("SectionNavigator");

	if (sections.length <= 1) {
		return null;
	}

	return (
		<chakra.div __css={styles.wrapper}>
			{sections.map(({ anchorId, title }) => (
				<SectionNavigatorItem
					key={anchorId}
					anchorId={anchorId}
					title={title}
				/>
			))}
		</chakra.div>
	);
};

// 🔬 TBD: Please evaluate
