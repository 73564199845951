import {
	isHeading,
	isParagraph,
	isRoot,
	isList,
} from "datocms-structured-text-utils";
import React from "react";
import { renderNodeRule } from "react-datocms";
import { Hx } from "../Heading/Heading";

export const HeadingRenderer = renderNodeRule(
	isHeading,
	({ node, children, key }) => {
		const level = Math.max(1, Math.min(node.level, 6));

		return (
			<Hx
				data-structured-text-block="heading"
				increment={level - 1}
				key={key}
				autoSize={true}
			>
				{children}
			</Hx>
		);
	},
);

export const ParagraphRenderer = renderNodeRule(
	isParagraph,
	({ children, key }) => {
		return (
			<p data-structured-text-block="paragraph" key={key}>
				{children}
			</p>
		);
	},
);

export const UnorderedListRenderer = renderNodeRule(
	isList,
	({ children, key, ancestors }) => {
		if (ancestors[0] && isRoot(ancestors[0])) {
			return (
				<ul data-structured-text-block="list" key={key}>
					{children}
				</ul>
			);
		}

		return children;
	},
);

// 🔬 TBD: Please evaluate
