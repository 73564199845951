import { chakra } from "@chakra-ui/react";
import { renderMarkRule } from "react-datocms/structured-text";

export const HighlightRule = renderMarkRule(
	"highlight",
	({ children, key }) => {
		return (
			<chakra.cite
				key={key}
				__css={{
					bg: "none",
					fontSize: "xs",
					fontStyle: "normal",
				}}
			>
				{children}
			</chakra.cite>
		);
	},
);

// 🔬 TBD: Please evaluate
