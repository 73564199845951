import { ChakraProps, chakra, visuallyHiddenStyle } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { InlineResponsiveSvgFragment } from "src/__generated__/cms-schema.codegen";
import { ChakraNextImage } from "src/components/Dato/DatoAsset";
import { makeSvgDisplayResponsive } from "./utils";
import { DatoRichTextContent } from "../Dato/DatoRichTextContent";

export type ResponsiveSvgProps = ChakraProps & {
	data: InlineResponsiveSvgFragment;
};

export const ResponsiveSvg: React.FC<ResponsiveSvgProps> = ({
	data,
	...props
}) => {
	const images = useMemo(() => makeSvgDisplayResponsive(data), [data]);

	const id = `caption-${data.id}`;

	return (
		<chakra.figure role="group" {...props}>
			{images.map(({ display, url, width, height, id, alt }) => (
				<chakra.div key={id} display={display} textAlign="center">
					<ChakraNextImage
						src={url}
						width={width ?? 0}
						height={height ?? 0}
						alt={alt ?? ""}
						aria-describedby={id}
					/>
				</chakra.div>
			))}

			<DatoRichTextContent
				sx={visuallyHiddenStyle}
				id={id}
				as="figcaption"
				html={data.alternativeText}
			/>
		</chakra.figure>
	);
};

// 🔬 unit tests and story
