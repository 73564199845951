import {
	useMultiStyleConfig,
	AspectRatio,
	chakra,
	Button,
	useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useTranslatedString } from "src/i18n/i18n";
import { getAnalyticsId } from "src/lib/utils.esm";
import { VideoPlayerModal } from "./VideoPlayerModal";
import { ChakraNextImage, DatoAsset, DatoAssetData } from "../Dato/DatoAsset";

export type VideoPlayerProps = {
	name: string;
	videoSrc: string;
	autoPosterSrc: string;
	poster?: DatoAssetData;
	aspectRatio?: number;
};

export const VideoPlayerTrigger: React.FC<VideoPlayerProps> = ({
	aspectRatio = 16 / 9,
	autoPosterSrc,
	poster,
	videoSrc,
	name,
}) => {
	const { onOpen, ...disclosure } = useDisclosure();
	const styles = useMultiStyleConfig("VideoPlayer");
	const t = useTranslatedString();
	const alt = t("videoPlayer.alt", { name });

	return (
		<>
			<AspectRatio ratio={aspectRatio} sx={styles.triggerWrapper}>
				<Button
					id={`video_player_trigger_${getAnalyticsId(name)}`}
					variant="unstyled"
					sx={styles.triggerButton}
					aria-label={t("videoPlayer.cta")}
					onClick={onOpen}
					className="video-player-trigger"
				>
					<chakra.div __css={styles.triggerImage}>
						{poster ? (
							<DatoAsset
								datoImageProps={{
									layout: "fill",
									objectFit: "cover",
								}}
								alt={alt}
								data={poster}
							/>
						) : (
							<ChakraNextImage
								src={autoPosterSrc}
								alt={alt}
								layout="fill"
							/>
						)}
					</chakra.div>
				</Button>
			</AspectRatio>
			<VideoPlayerModal
				aspectRatio={aspectRatio}
				src={videoSrc}
				poster={autoPosterSrc}
				{...disclosure}
			/>
		</>
	);
};

// 🔬 ladle -> e2e
