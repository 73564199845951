import { InlineResponsiveSvgFragment } from "src/__generated__/cms-schema.codegen";

export const breakpoints = ["base", "sm", "md", "lg", "xl", "2xl"] as const;

export type Breakpoint = (typeof breakpoints)[number];

export const makeSvgDisplayResponsive = (data: InlineResponsiveSvgFragment) => {
	// only the steps' breakpoints as an Array<Breakpoint>
	const stepBreakpoints = data.steps.map((s) => s.breakpoint as Breakpoint);

	// our base object
	const allNone = {
		base: "none",
		...Object.fromEntries(stepBreakpoints.map((b) => [b, "none"])),
	};

	// the smallest available bp in steps
	const lowestStepBreakpoint = stepBreakpoints[0];

	// matching assets to their respective breakpoint
	const steps = data.steps.map(({ asset, breakpoint }) => ({
		...asset,
		display: { ...allNone, [breakpoint]: "block" },
	}));

	// for some reason, no steps were returned, let's just display the base asset
	// note: this should not happen, since the dato field for steps has a min(1)
	if (!lowestStepBreakpoint) {
		return [
			{
				...data.baseAsset,
				display: "block",
			},
		];
	}

	// the base asset is only displayed until the lowest breapoint in steps
	const baseAsset = {
		...data.baseAsset,
		display: { base: "block", [lowestStepBreakpoint]: "none" },
	};

	// merge them together
	return [baseAsset, ...steps];
};

// 🔬 unit!
