import {
	chakra,
	useMultiStyleConfig,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
	AspectRatio,
} from "@chakra-ui/react";
import React from "react";
import { HlsPlayer } from "../HlsPlayer";
import { Modal } from "../Modal";
import { NoSSR } from "../NoSSR/NoSSR";

export type VideoPlayerModalProps = Omit<
	ReturnType<typeof useDisclosure>,
	"onOpen"
> & {
	src: string;
	poster: string;
	aspectRatio: number;
};

export const VideoPlayerModal: React.FC<VideoPlayerModalProps> = ({
	poster,
	aspectRatio = 16 / 9,
	src,
	onClose,
	isOpen,
}) => {
	const styles = useMultiStyleConfig("VideoPlayer");

	return (
		<NoSSR>
			<Modal onClose={onClose} isOpen={isOpen}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader />
					<ModalBody>
						<chakra.div __css={styles.modalInner}>
							<AspectRatio ratio={aspectRatio}>
								<HlsPlayer
									tabIndex={0}
									autoPlay
									src={src}
									poster={poster}
									controls
								/>
							</AspectRatio>
						</chakra.div>
					</ModalBody>
					<ModalCloseButton data-testid="close-modal" />
				</ModalContent>
			</Modal>
		</NoSSR>
	);
};

// 🔬 ladle -> e2e
