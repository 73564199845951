import { chakra, Tag, useMultiStyleConfig } from "@chakra-ui/react";

type PersonaFactProps = {
	name: string;
	value: string;
};

export const PersonaFact: React.FC<PersonaFactProps> = ({ name, value }) => {
	const styles = useMultiStyleConfig("Persona");

	return (
		<Tag variant="fact" sx={styles.fact}>
			{/* eslint-disable-next-line react/jsx-no-literals */}
			<chakra.strong __css={styles.factName}>{name}</chakra.strong>:
			&nbsp;
			<chakra.span __css={styles.factValue}>{value}</chakra.span>
		</Tag>
	);
};

// 🔬 TBD: Please evaluate
