import { LinkOverlay, useMultiStyleConfig } from "@chakra-ui/react";
import { InlineLinkButtonFragment } from "src/__generated__/client.codegen";
import { useGetInternalHref } from "src/components/CMSButton/CMSLinkButton";
import { NextLink } from "src/components/NextLink";
import { ArrowRightLong } from "src/icons";
import { useGTMJumpToProducts } from "src/lib/gtm";

export const OfferLink: React.FC<{
	linkData: InlineLinkButtonFragment;
}> = ({ linkData }) => {
	const trackProductClick = useGTMJumpToProducts();

	const internalHref = useGetInternalHref(linkData.internal ?? null);

	const styles = useMultiStyleConfig("Offer");

	switch (linkData.linkType) {
		case "internal":
			return (
				<NextLink href={internalHref}>
					<LinkOverlay
						id={linkData.analyticsId}
						sx={styles.readMore}
						onClick={() => trackProductClick(linkData.label)}
					>
						{linkData.label}
						<ArrowRightLong />
					</LinkOverlay>
				</NextLink>
			);
		case "external":
			return (
				<LinkOverlay
					id={linkData.analyticsId}
					sx={styles.readMore}
					onClick={() => trackProductClick(linkData.label)}
					href={linkData.url ?? "#"}
					isExternal
				>
					{linkData.label}
					<ArrowRightLong />
				</LinkOverlay>
			);
		case "on-page":
			return (
				<LinkOverlay
					id={linkData.analyticsId}
					sx={styles.readMore}
					onClick={() => trackProductClick(linkData.label)}
					href={linkData.jumpMark ?? "#"}
				>
					{linkData.label}
					<ArrowRightLong />
				</LinkOverlay>
			);
		default:
			return null;
	}
};

// 🔬 TBD: Please evaluate
