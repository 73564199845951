import { Box, BoxProps } from "@chakra-ui/react";

/**
 * DatoCMS sanitizes multiline field content for us,
 * since we only use that content with dangerouslySetInnerHTML, we can
 * safely ignore eslint's warning here.
 */

export const DatoRichTextContent = ({
	html,
	...props
}: BoxProps & { html: string }) => (
	<Box dangerouslySetInnerHTML={{ __html: html }} {...props} />
);

// 🔬 TBD: Please evaluate
