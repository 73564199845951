import { chakra, ChakraProps, useMultiStyleConfig } from "@chakra-ui/react";
import { ReactNode } from "react";
import { HeadingLevelBoundary, Hx } from "src/components/Heading/Heading";
import {
	StructuredText,
	StructuredTextData,
	useGroupedStructuredText,
} from "src/components/StructuredText/StructuredText";
import { useCurrencyFormatter } from "src/lib/hooks";
import { replaceStringsWithReactNodes } from "src/lib/replaceStringWithReactNode";
import { DatoAsset, DatoAssetData } from "../Dato/DatoAsset";

export type ProductProps = ChakraProps & {
	icon: DatoAssetData | null;
	title: string;
	headerAlignment: string;
	startingPrice: number;
	priceDescription: string | null;
	description?: StructuredTextData;
	children?: ReactNode;
};

export const FormattedPriceDescription: React.FC<
	{
		price: number;
		priceDescription?: string;
		insertSpace?: boolean;
	} & ChakraProps
> = ({ price, priceDescription, insertSpace, ...props }) => {
	const formatPrice = useCurrencyFormatter();

	if (!priceDescription) {
		return insertSpace ? <>&nbsp;</> : null;
	}

	return (
		<chakra.div {...props}>
			{replaceStringsWithReactNodes(priceDescription, {
				price: <strong>{formatPrice(price)}</strong>,
			})}
		</chakra.div>
	);
};

export const Products: React.FC<{ children: ReactNode }> = ({ children }) => {
	const styles = useMultiStyleConfig("Product");

	return <chakra.div __css={styles.items}>{children}</chakra.div>;
};

export const Product: React.FC<ProductProps> = ({
	icon,
	title,
	headerAlignment,
	startingPrice,
	priceDescription,
	description: descriptionRaw,
	children,
	...rest
}) => {
	const styles = useMultiStyleConfig("Product", { variant: headerAlignment });

	const [description, ...blocks] = useGroupedStructuredText(descriptionRaw, [
		"intro",
		"rest",
	]);

	return (
		<chakra.div __css={styles.wrapper} {...rest} data-testid="product">
			<chakra.div __css={styles.inner}>
				<chakra.div __css={styles.headingWrapper}>
					{icon && (
						<chakra.div __css={styles.iconWrapper}>
							<DatoAsset
								alt=""
								as="div"
								data={icon}
								sx={styles.icon}
							/>
						</chakra.div>
					)}
					<HeadingLevelBoundary>
						<Hx size="h3" sx={styles.heading}>
							{title}
						</Hx>
					</HeadingLevelBoundary>
					<FormattedPriceDescription
						insertSpace
						sx={styles.startingPrice}
						price={startingPrice}
						priceDescription={priceDescription ?? undefined}
					/>
				</chakra.div>

				{description && <StructuredText data={description} />}
				{blocks.map((block) => (
					<chakra.div key={block.key} marginTop="auto">
						<StructuredText data={block} />
					</chakra.div>
				))}
				{children}
			</chakra.div>
		</chakra.div>
	);
};

// 🔬 TBD: Please evaluate
